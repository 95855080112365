import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "../css/ssslanding.css";
import sssImage from "../images/sss/sss.png";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const AwwexLandingSSS = () => {
  const [activeIndexes, setActiveIndexes] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8,
  ]);

  const toggleAccordion = (index) => {
    setActiveIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    );
  };
  useEffect(() => {
    // Sayfa her yüklendiğinde sayfanın en üstüne kaydırılır
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Sıkça Sorulan Sorular - Awwex </title>
      </Helmet>
      <div>
        <section className="page-title" id="ssscontent">
          <div className="image-container">
            <img src={sssImage} alt="Privileges Image" />
            <div className="overlay"></div>
            <h2 className="title">SIKÇA SORULAN SORULAR</h2>
          </div>
        </section>

        <section className="accordion-section">
          <div className="accordion">
            {[
              {
                question:
                  "Yurt dışı gönderilerinde gönderici ve alıcı sorumlulukları nelerdir?",
                answer: (
                  <>
                    <b>Göndericinin Sorumlulukları:</b>
                    <ul>
                      <li>
                        Alıcıya ait adresi ve telefon numarasını doğru vermek
                      </li>
                      <li>
                        Yükü hasar almayacak şekilde dikkatli ve özenli bir
                        şekilde paketlemek
                      </li>
                      <li>
                        Taşınması yasak veya sakıncalı ürünler göndermemek
                      </li>
                      <li>
                        Gönderi teslim edilene kadar, takip numarası ile
                        gönderiyi takip etmek ve herhangi bir değişiklik ya da
                        olumsuzlukta taşıyıcıya bilgi vermek
                      </li>
                    </ul>
                    <b>Alıcının Sorumlulukları:</b>
                    <ul>
                      <li>
                        Kendisine ulaşacak gönderiyi takip ederek, dağıtım günü
                        teslimat adresinde bulunmak
                      </li>
                      <li>Gönderiyi kontrol ederek teslim almak</li>
                      <li>
                        Teslimat esnasında belirtilen teslim adresinde
                        bulunmayacak ise, bu bilgiyi taşıyıcı firma ile
                        paylaşmak
                      </li>
                      <li>
                        Bulunduğu ülke gümrükleri tarafından istenen belge ve
                        bilgileri eksiksiz sağlamak
                      </li>
                      <li>
                        Varış gümrüğünde oluşabilecek vergi ve sorumlulukları
                        yerine getirmek
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                question: "GTIP kodu nedir? GTIP kodunu nereden bulabilirim?",
                answer: (
                  <p>
                    GTİP, Gümrük Tarife İstatistik Pozisyonu’nun kısaltmasıdır.
                    İhraç edilecek ürünlerin kontrollerinin sağlanması, ödenecek
                    gümrük ve nakliye vergilerinin tespit edilmesi, menşe
                    kurallarının belirlenmesi gibi kritik konularda kullanılan,
                    ürünle alakalı detaylı bilgiler içeren sistematik bir
                    numaralandırma sistemidir. İngilizce karşılığı HS Code, yani
                    Harmonized Commodity Description and Coding System olarak
                    bilinmektedir.
                  </p>
                ),
              },
              {
                question: "Proforma Fatura (Proforma Invoice) nedir?",
                answer: (
                  <p>
                    Proforma fatura; sadece ticari olmayan ürünlerin içeriğini,
                    kıymetini, miktarını, gönderici, alıcı ve taşıyıcı
                    bilgilerini içeren bir beyan veya ön faturadır. Resmi bir
                    satış faturası yerine geçmez ve genellikle numune
                    gönderimlerinde kullanılır. Gönderici tarafından Word, Excel
                    veya PDF formatlarında hazırlanır.
                  </p>
                ),
              },
              {
                question:
                  "Ticari Fatura (Commercial Invoice veya Invoice) nedir?",
                answer: (
                  <p>
                    Satışı gerçekleşmiş ürün veya ürünler için hazırlanan resmi
                    faturadır. İhracatçı ve ithalatçı bilgilerinin yer aldığı
                    fatura, içeriğin tanımı, değeri, adeti, GTIP No (HS Kodu),
                    taşıyıcı bilgileri ve satış/ödeme şekillerini gösterir.
                    Ticari faturalar, uluslararası ticarette önemli bir belgedir
                    ve gümrük işlemleri için gereklidir.
                  </p>
                ),
              },
              {
                question:
                  "Proforma Fatura ile Ticari Fatura arasındaki fark nedir?",
                answer: (
                  <p>
                    İki fatura arasındaki en önemli fark; proforma faturanın bir
                    numune için hazırlanması ve maddi veya ticari bir değerinin
                    olmamasıdır. Ticari fatura ise satışı yapılmış ürün veya
                    ürünler için hazırlanır.
                  </p>
                ),
              },
              {
                question: "Konşimento numarası (AWB No) ne anlama geliyor?",
                answer: (
                  <p>
                    Konşimento numarası; konşimento belgesinin üzerinde yazan ve
                    taşıyıcı kargo şirketine ait web sitesinden gönderinizi
                    takip etmenizi sağlayan kodu ifade etmektedir. Bu numara,
                    gönderinize özel sayı veya harflerden oluşan bir dizindir ve
                    “Tracking Number” olarak da bilinmektedir.
                  </p>
                ),
              },
              {
                question: "Gizlilik ve Koruma",
                answer: (
                  <p>
                    Güvenliğiniz bizim için en öncelikli konulardan biridir. Bu
                    sebeple bizimle paylaşacağınız kişisel verileriniz gerek
                    teknik altyapımız gerekse de hassasiyetlerimiz neticesinde
                    yüksek seviyede korunmaktadır. Asset Lojistik A.Ş. olarak,
                    kişisel verilerin korunması politikası ile kişisel
                    verilerinizin hangi amaçla işleneceği, işlenen verilerin
                    neden paylaşılabileceği ile işlenen verilerinize ilişkin
                    haklarınızın neler olduğu hususunda sizleri aydınlatmayı
                    amaçlıyoruz.
                  </p>
                ),
              },
              {
                question:
                  "Toplanan Kişisel Verileriniz, Toplanma Yöntemi ve Hukuki Sebebi",
                answer: (
                  <p>
                    IP adresiniz ve kullanıcı aracısı bilgileriniz, sadece
                    analiz yapmak amacıyla ve çerezler vb. teknolojiler
                    vasıtasıyla, otomatik veya otomatik olmayan yöntemlerle ve
                    bazen de analitik sağlayıcılar, reklam ağları, arama bilgi
                    sağlayıcıları, teknoloji sağlayıcıları gibi üçüncü
                    taraflardan elde edilerek, kaydedilerek, depolanarak ve
                    güncellenerek, aramızdaki hizmet ve sözleşme ilişkisi
                    çerçevesinde ve süresince, meşru menfaat işleme şartına
                    dayanılarak işlenecektir.
                  </p>
                ),
              },
              {
                question:
                  "Toplanan Kişisel Verilerin Kimlere ve Hangi Amaçlarla Aktarılabileceği",
                answer: (
                  <p>
                    Bizimle paylaştığınız kişisel verileriniz; faaliyetlerimizi
                    yürütmek üzere hizmet aldığımız ve/veya verdiğimiz,
                    sözleşmesel ilişki içerisinde bulunduğumuz, işbirliği
                    yaptığımız, yurt içi ve yurt dışındaki 3. şahıslar ile kurum
                    ve kuruluşlara ve talep halinde adli ve idari makamlara,
                    gerekli teknik ve idari önlemler alınması koşulu ile
                    aktarılabilecektir.
                  </p>
                ),
              },
            ].map((item, index) => (
              <div className="accordion-item" key={index}>
                <button
                  className="accordion-header"
                  onClick={() => toggleAccordion(index)}
                >
                  {item.question}
                  <span className="arrow">
                    {activeIndexes.includes(index) ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </span>
                </button>
                {activeIndexes.includes(index) && (
                  <div className="accordion-content">{item.answer}</div>
                )}
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default AwwexLandingSSS;
