import { postAPICallTypeArrayBuffer, getAPICall, postAPICall, putAPICall, deleteAPICall } from '../datalayer';
import getMyMessage from "../../util/RestReturnCatchForException";

async function CallerGetDocumentFileListByCompany(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/Documentwx/GetDocumentFileListByCompany', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

async function CallerGetDocumentFile(reqTempItem){

    //debugger;
        try {
            const response = await postAPICallTypeArrayBuffer('/portalbend/Documentwx/GetDocumentFile', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

async function CallerCreateOrUpdateDocumentFileByCompany(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/Documentwx/CreateOrUpdateDocumentFileByCompany', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

async function CallerEmailDocumentFileApproveProcessFromAgent(reqTempItem){

  //debugger;
  try {
    const response = await postAPICall('/portalbend/Documentwx/EmailDocumentFileApproveProcessFromAgent', reqTempItem);
    return response.data;
  }
  catch(error){
    return error.response;
  }
}



async function CallerGetDocumentsForAllCompanies(reqTempItem){
  try {
    const response = await postAPICall('/portalbend/Documentwx/GetDocumentsForAllCompanies', reqTempItem);
    return response.data;
  }
  catch(error){
    return error.response;
  }
}

async function CallerUpdateApprovalStatusByDocument(reqTempItem){
    try {
      const response = await postAPICall('/portalbend/Documentwx/UpdateApprovalStatusByDocument', reqTempItem);
      return response;
    }
    catch (error) {
        let jsonRtrn = {}
        try {
          jsonRtrn = getMyMessage(error);
        } catch (error) {
          jsonRtrn.ReturnCode = 500;
          jsonRtrn.ReturnText = error.response;
        }
        return jsonRtrn;
      }
  }

export {CallerEmailDocumentFileApproveProcessFromAgent, CallerGetDocumentFileListByCompany, CallerGetDocumentFile, CallerCreateOrUpdateDocumentFileByCompany, CallerGetDocumentsForAllCompanies,CallerUpdateApprovalStatusByDocument}
