import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Input, InputNumber, Radio, Row, Select, Table, Tooltip, Space, Alert} from 'antd';
import {InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {CallerPricingGetController} from '../../datalayer/pricing/callerpricingcontroller';
import DynamicFieldForPiece from '../commonmodel/dynamicfieldsforpiece';
import {CallerGetCountryListController} from "../../datalayer/definations/callerdefinationcontroller";

export default function PricingGet(props) {

  const [form] = Form.useForm();
  const [shipmentType, setShipmentType] = useState(2);
  const [data, setData] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const shipperLogos = require.context('../../assets/shipperlogos', true);
  const [pricingValidationMessages, setPricingValidationMessages] = useState([]);

  const fetchCountries = async (value) => {
    let request = { SearchText: value };
    let response = await CallerGetCountryListController(request);
    if (response) {
        const options = response.map(country => ({
        key: country.id,
        value: country.code,
        label: country.name
      }));
      setCountryOptions(options);
    }
  };

  const handleShipmentTypeChange = async (e) => {
    setShipmentType(e.target.value);

    const values = await form.getFieldsValue();

    if (e.target.value === 1 && values?.fields) {
      form.setFieldValue('fields', undefined);
    } else if (e.target.value === 2) {
      form.setFieldValue('fields', [{}]);
      form.setFieldValue('DocumentWeight', undefined);
    }

    await changeFormData();
  };

  useEffect(async () => {
    await fetchCountries();
    localStorage.setItem('pageName', 'Fiyatlandırma')

    try {
      const externalPageRequest = props.location.pathname.split("/")[2] != 'pricingget' ? JSON.parse(decodeURIComponent(props.location.pathname.split("/")[2])) : null;
      if (externalPageRequest){
        form.setFieldsValue({
          PackageType: parseInt(externalPageRequest.packagetype),
          ShipmentType: parseInt(externalPageRequest.posttype),
          ReceiverCountryCode: externalPageRequest.country,
          ReceiverZipCode: externalPageRequest.zipcode,
        });
      }
    } catch (error) {
      console.log('externalPageRequest error',error)
    }
  },[]);

  useEffect( async () => {
    await fetchCountries();
    localStorage.setItem('pageName', 'Fiyatlandırma')

    try {
      if (props.externalPageRequest){
        form.setFieldsValue({
          PackageType: parseInt(props.externalPageRequest.packagetype),
          ShipmentType: parseInt(props.externalPageRequest.posttype),
          ReceiverCountryCode: props.externalPageRequest.country,
          ReceiverZipCode: props.externalPageRequest.zipcode,
        });
      }
    } catch (error) {
      console.log('externalPageRequest error',error)
    }
  },[props.externalPageRequest]);

  async function changeFormData(){
    try {
      const fieldsValues = await form.getFieldValue('fields');
      let piecesConvert = [];
      if (fieldsValues) {
        const pieces = fieldsValues.map(x => {
          const pieceVolumeWeight = (x.PieceWidth * x.PieceLength * x.PieceHeight) / 5000;
          return {...x, PieceVolumeWeight: pieceVolumeWeight}
        });
        form.setFieldValue('fields', pieces);

        piecesConvert = fieldsValues.map(x => {
          return {...x , Count: x.PieceCount,Height: x.PieceHeight, Length:x.PieceLength, VolumeWeight: x.PieceVolumeWeight, Weight:x.PieceWeight, Width:x.PieceWidth}
        });
      }
      const values = await form.getFieldsValue();
      const data =
      {
          ShipmentType: values.ShipmentType,
          PackageType: values.PackageType,
          CountryCode: values.ReceiverCountryCode,
          ZipCode: values.ReceiverZipCode,
          DocumentWeight: values.DocumentWeight,
          Pieces: piecesConvert,
      };

      const pricingGetResponse = await CallerPricingGetController(data);
      if (pricingGetResponse.items) {
        setData(pricingGetResponse.items);
        setPricingValidationMessages([]);
      }
      else if (pricingGetResponse.ReturnText){
        setPricingValidationMessages([pricingGetResponse.ReturnText])
        setData([]);
      } else {
        setPricingValidationMessages([]);
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
}

  const columns = [{
    title: 'Tedarikçi', dataIndex: 'supplierName', key: 'supplierName', render: (_, record) => {
      return (<img src={shipperLogos(`./${record.logoImageName}`).default} alt={`${record.supplierName}`}
                   style={{width: '120px'}}/>)
    }
  }, {title: 'Tedarikçi Servis Şekli', dataIndex: 'supplierServiceMethodName', key: 'supplierServiceMethodName'}, {
    title: 'Toplam Ücretlendirilen Ağırlık',
    dataIndex: 'totalChargedWeight',
    key: 'totalChargedWeight',
    render: (_, record) => (`${record.totalChargedWeight} kg`),
  }, {
    title: 'Toplam Fiyat',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    render: (_, record) => (record.explanation ? record.explanation : `${record.totalPrice} ${record.priceCurrency} (${record.totalPriceInTry} TRY)`),
  }
  ];

  return (<>
    <Card>
      <Row>
        <Col span={24}>
          <Card className="gx-card">
            <Form
              name="pricing"
              form={form}
              autoComplete="off"
              className='gx-form-row0'
              labelCol={{
                span: 10
              }}
              wrapperCol={{
                span: 14
              }}
              initialValues={{
                ShipmentType: 2,
                PackageType: 1,
              }}
            >
              <Form.Item
                name="ShipmentType"
                label="Gönderi Tipi"
                rules={[{
                  required: true, message: 'Lütfen gönderi tipi seçiniz!',
                },]}
              >
                <Radio.Group onChange={handleShipmentTypeChange}>
                  <Radio value={2}>Paket</Radio>
                  <Radio value={1}>Doküman</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Paket Tipi"
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Item
                    name="PackageType"
                    rules={[{ required: true, message: 'Lütfen paket tipi seçiniz!' }]}
                    style={{ marginBottom: 0 }}
                    onChange={changeFormData}
                  >
                    <Radio.Group>
                      <Radio value={1}>Koli</Radio>
                      <Radio value={2}>PAK</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Tooltip title={"2,5 kg'a kadar PAK seçebilirsiniz."}>
                    <InfoCircleOutlined style={{ marginLeft: 8 }} />
                  </Tooltip>
                </div>
              </Form.Item>
              <Form.Item
                name="ReceiverCountryCode"
                label="Alıcı Ülkesi"
                rules={[{
                  required: true, message: 'Lütfen alıcı ülkesi seçiniz!',
                },]}
              >
                <Select
                    showSearch
                    placeholder="Seçiniz"
                    options={countryOptions}
                    onChange={changeFormData}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                >
                </Select>
              </Form.Item>
              <Form.Item
                name="ReceiverZipCode"
                label="Alıcı Posta Kodu"
                rules={[{
                  required: true, message: 'Lütfen alıcı posta kodunu giriniz!',
                },]}
              >
                <Input onChange={changeFormData}/>
              </Form.Item>
              <Form.Item
                name="DocumentWeight"
                label="Ağırlık (kg)"
                hidden={shipmentType === 2}
                rules={[{
                  required: shipmentType !== 2, message: 'Lütfen ağırlık giriniz!',
                }]}
              >
                <InputNumber style={{width: '100%'}} step="0.01" min="0" onChange={changeFormData}/>
              </Form.Item>
              {shipmentType === 2 && <DynamicFieldForPiece changeFormData={changeFormData} form={form}></DynamicFieldForPiece>}
            </Form>
            {pricingValidationMessages.length > 0 &&
                <Alert message={pricingValidationMessages.join('\n')} type="warning" showIcon/>
            }
            <Table columns={columns}
                   expandable={{
                     expandIcon: ({
                                    expanded,
                                    onExpand,
                                    record
                                  }) => Array.isArray(record.surcharges) && record.surcharges.length ? (expanded ? (
                       <MinusCircleOutlined
                         style={{fontSize: '20px'}}
                         onClick={(e) => onExpand(record, e)}
                       />) : (<PlusCircleOutlined
                         style={{fontSize: '20px'}}
                         onClick={(e) => onExpand(record, e)}
                       />)) : null, expandedRowRender: (record) => {
                       return (<div key={record.key}>
                           {Array.isArray(record.surcharges) && record.surcharges.length && record.surcharges.map((surcharge, index) => {
                             return (<p key={index} style={{margin: 0}}>
                                 {surcharge.costName}: {surcharge.price} {record.priceCurrencyName} ({surcharge.priceInTry} TRY)
                               </p>);
                           })}
                         </div>);
                     }, rowExpandable: (record) => Array.isArray(record.surcharges) && record.surcharges.length,
                   }}
                   dataSource={data}
                   rowKey="id"
            />
          </Card>
        </Col>
      </Row>
    </Card>
  </>);
}
