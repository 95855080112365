import React from "react";
import {Redirect, Route, Switch, useParams } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

function Params() {
  let { userid, billofladingid } = useParams();
}

function UpdateTrackingStatusToMatchParams() {
  let { trackingStatusId } = useParams();
}

const AgentApps = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/agent`}/>

    <Route path={`${match.url}/documentreview`} component={asyncComponent(() => import('./documentreview.js'))}/>
    <Route path={`${match.url}/shipment/:userid/:billofladingid`} element={Params} component={asyncComponent(() => import('../shipment/shipmentcreate.js'))}/>
    <Route path={`${match.url}/shipmentlist`} component={asyncComponent(() => import('./operationshipmentlistparent.js'))}/>    
    <Route path={`${match.url}/manifest`} component={asyncComponent(() => import('./manifest.js'))}/>    
    <Route path={`${match.url}/trackingstatuslist/:trackingStatusId`} element={UpdateTrackingStatusToMatchParams} component={asyncComponent(() => import('./trackingstatuslist.js'))}/>
    <Route path={`${match.url}/trackingstatuslist`} component={asyncComponent(() => import('./trackingstatuslist.js'))}/>
    <Route path={`${match.url}/customizedtrackingstatuslist`} component={asyncComponent(() => import('./customizedtrackingstatuslist.js'))}/>
    <Route path={`${match.url}/shipmenttrackingstatusescreate`} component={asyncComponent(() => import('./shipmenttrackingstatusescreate.js'))}/>
    <Route path={`${match.url}/customerservices`} component={asyncComponent(() => import('./customerservices.js'))}/>

  </Switch>
);

export default AgentApps;
