import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/icons/logo.png";
import facebookIcon from "../images/icons/facebook.png";
import instagramIcon from "../images/icons/instagram.png";
import twitterIcon from "../images/icons/x.png";
import linkedinIcon from "../images/icons/linkedin.png";
import whatsappIcon from "../images/icons/whatsapp.png";

function AwwexLandingColoredHeader(props) {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <header className="colored-header">
      <div className="logo">
        <a href="https://www.awwex.com">
          <img src={logo} alt="Logo" />
        </a>
      </div>
      <div className="auth-links">
        <a href="#" className="auth-link">
          KAYIT OL
        </a>
        <a href="#" className="auth-link">
          OTURUM AÇ
        </a>
      </div>

      <div className="social-icons">
        <div className="social-icon">
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebookIcon} alt="Facebook" />
          </a>
        </div>
        <div className="social-icon">
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagramIcon} alt="Instagram" />
          </a>
        </div>
        <div className="social-icon">
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitterIcon} alt="Twitter" />
          </a>
        </div>
        <div className="social-icon">
          <a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedinIcon} alt="LinkedIn" />
          </a>
        </div>
        <div className="social-icon">
          <a
            href="https://www.whatsapp.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whatsappIcon} alt="WhatsApp" />
          </a>
        </div>
      </div>

      {/* Hamburger menu */}
      <div className="hamburger-menu" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Mobile navigation */}
      <nav className={`mobile-nav ${menuActive ? "active" : ""}`}>
        <ul>
          <li>
            <a href="#">OTURUM AÇ</a>
          </li>
          <li>
            <a href="#">KAYDOL</a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default AwwexLandingColoredHeader;
