import {postAPICall} from '../datalayer';
import getMyMessage from "../../util/RestReturnCatchForException";

async function CallerPullShipmentTrackingStatusCommand(reqTempItem) {
  try {
    const response = await postAPICall('/portalbend/Tracking/PullShipmentTrackingStatusCommand', reqTempItem);
    return response;
  }
  catch (error) {
    let jsonRtrn = {}
    try {
      jsonRtrn = getMyMessage(error);
    } catch (error) {
      jsonRtrn.ReturnCode = 500;
      jsonRtrn.ReturnText = error.response;
    }
    return jsonRtrn;
  }
}

async function CallerGetTrackingStatuses(reqTempItem) {
  try {
    const response = await postAPICall('/portalbend/Tracking/GetTrackingStatusesQuery', reqTempItem);
    return response.data;
  }
  catch (error) {
    return error.response;
  }
}

async function CallerGetCustomizedTrackingStatusListController(reqTempItem) {
  try {
    const response = await postAPICall('/portalbend/Tracking/GetCustomizedTrackingStatusListQuery', reqTempItem);
    return response.data;
  }
  catch (error) {
    return error.response;
  }
}

async function CallerCreateTrackingStatusController(reqTempItem) {
  try {
    return await postAPICall('/portalbend/Tracking/CreateTrackingStatusCommand', reqTempItem);
  }
  catch (error) {
    let jsonRtrn = {}
    try {
      jsonRtrn = getMyMessage(error);
    } catch (error) {
      jsonRtrn.ReturnCode = 500;
      jsonRtrn.ReturnText = error.response;
    }
    return jsonRtrn;
  }
}

async function CallerCreateCustomizedTrackingStatusController(reqTempItem) {
  try {
    return await postAPICall('/portalbend/Tracking/CreateCustomizedTrackingStatusCommand', reqTempItem);
  }
  catch (error) {
    let jsonRtrn = {}
    try {
      jsonRtrn = getMyMessage(error);
    } catch (error) {
      jsonRtrn.ReturnCode = 500;
      jsonRtrn.ReturnText = error.response;
    }
    return jsonRtrn;
  }
}

async function CallerCreateShipmentTrackingStatusesController(data) {
  try {
    const response = await postAPICall("/portalbend/Tracking/CreateShipmentTrackingStatusesCommand", data);
    return response;
  } catch (error) {
    let jsonRtrn = {}
    try {
      jsonRtrn = getMyMessage(error);
    } catch (error) {
      jsonRtrn.ReturnCode = 500;
      jsonRtrn.ReturnText = error.response;
    }
    return jsonRtrn;
  }
}

export { CallerPullShipmentTrackingStatusCommand, CallerGetTrackingStatuses, CallerGetCustomizedTrackingStatusListController,
  CallerCreateCustomizedTrackingStatusController, CallerCreateTrackingStatusController, CallerCreateShipmentTrackingStatusesController}
