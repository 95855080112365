import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import {
  Tooltip,
  Tabs,
  Modal,
  AutoComplete,
  Collapse,
  Radio,
  Col,
  Row,
  Card,
  Select,
  Space,
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Alert,
  Checkbox,
  message,
  Carousel
} from 'antd';
import VideoPlayer from './VideoPlayer';
import VideoOverlay from "./VideoOverlayV2";
import PricingGet from "../routes/pricing/pricingget";
import {CallerGetCountryListController} from "../datalayer/definations/callerdefinationcontroller";
function AwwexLandingPageHeader(props) {
  const [form] = Form.useForm();
  const contentStyle: React.CSSProperties = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  const styles = {
    container: {
      position: "relative",
      width: "100%",
      height: "100%",
      margin: "0 auto",
    },
    video: {
      width: "100%",
      height: "100%",
      display: "block",
    },
    overlayheader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // %50 siyah transparan
      display: "flex",
      justifyContent: "center",
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // %50 siyah transparan
      display: "none",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      color: "#fff",
      textAlign: "center",
      padding: "20px",
    },
    title: {
      fontSize: "24px",
      marginBottom: "20px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    input: {
      padding: "10px",
      fontSize: "16px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      outline: "none",
      width: "200px",
    },
    button: {
      padding: "10px",
      fontSize: "16px",
      borderRadius: "5px",
      backgroundColor: "#1677ff",
      color: "#fff",
      border: "none",
      cursor: "pointer",
    }
  };
  const [isHoveredSign, setIsHoveredSign] = useState(false);
  const [isHoveredRecord, setIsHoveredRecord] = useState(false);
  const [isHoveredFacebook, setIsHoveredFacebook] = useState(false);
  const [isHoveredInstagram, setIsHoveredInstagram] = useState(false);
  const [isHoveredX, setIsHoveredX] = useState(false);
  const [isHoveredLinkedin, setIsHoveredLinkedin] = useState(false);
  const [isHoveredWhatsapp, setIsHoveredWhatsapp] = useState(false);
  const [isHoveredLanguage, setIsHoveredLanguage] = useState(false);
  // Temel stil objesi
  const recordimageLinkStyle = {
    display: "block",
    width: "225px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredRecord
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_05.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_05.png')", // Hover durumuna göre resim değişir
  };

  const signimageLinkStyle = {
    display: "block",
    width: "219px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredSign
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_06.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_06.png')", // Hover durumuna göre resim değişir
  };

  const facebookimageLinkStyle = {
    display: "block",
    width: "65px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredFacebook
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_07.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_07.png')", // Hover durumuna göre resim değişir
  };

  const instagramimageLinkStyle = {
    display: "block",
    width: "55px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredInstagram
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_08.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_08.png')", // Hover durumuna göre resim değişir
  };

  const ximageLinkStyle = {
    display: "block",
    width: "55px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredX
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_09.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_09.png')", // Hover durumuna göre resim değişir
  };

  const linkedinimageLinkStyle = {
    display: "block",
    width: "55px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredLinkedin
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_10.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_10.png')", // Hover durumuna göre resim değişir
  };

  const whatsappimageLinkStyle = {
    display: "block",
    width: "57px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredWhatsapp
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_11.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_11.png')", // Hover durumuna göre resim değişir
  };

  const languageimageLinkStyle = {
    display: "block",
    width: "70px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredLanguage
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_12.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_12.png')", // Hover durumuna göre resim değişir
  };

  const [isHoveredPricingSubmit, setIsHoveredPricingSubmit] = useState(false);
  const pricingsubmitimageLinkStyle = {
    display: "block",
    width: "773px",
    height: "83px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredPricingSubmit
      ? "url('https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_32.png')"
      : "url('https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_32_first.png')", // Hover durumuna göre resim değişir
  };

  const { Option } = Select;
  const [countryData, setCountryData] = useState([]);
  const fetchCountries = async (value) => {
    let request = { SearchText: value };
    let response = await CallerGetCountryListController(request);
    if (response) {
      const options = response.map(country => ({
        key: country.id,
        value: country.code,
        label: country.name
      }));
      setCountryData(options);
    }
  };

  useEffect( async () => {
    await fetchCountries();
  },[]);

  const [isVisiblePricing, setIsVisiblePricing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [externalPageRequest, setExternalPageRequest] = useState(false);
  async function getexternalPageRequest()
  {
    const values = await form.getFieldsValue();
    let epr = {
      packagetype:values.pakettipi,
      posttype:values.gonderitipi,
      country:values.ulkeler,
      zipcode:values.postakodu
    }
    debugger;
    setExternalPageRequest(epr);
    showModal();

  }

  return (
    <div className="App" style={{backgroundColor:"black", margin:"0px auto"}}>
      <header className="App-header">
        <Carousel dotPosition={"top"} autoplay effect="fade" autoplaySpeed={5000}>
          <div>
            <div style={styles.container}>
              {/* Video oynatıcı */}
              <video autoPlay loop muted style={styles.video}>
                <source src="https://www.awwex.com/assets/images/Slide2.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div>
            <video autoPlay loop muted style={styles.video}>
              <source src="https://www.awwex.com/assets/images/Slide1.mp4" type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          </div>
          <div>
            <video autoPlay loop muted style={styles.video}>
              <source src="https://www.awwex.com/assets/images/Slide3.mp4" type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          </div>
        </Carousel>
        {/* Siyah transparan katman */}
        <div style={styles.overlayheader}>
          <table id="Table_01" width="1920"  border="0" cellPadding="0" cellSpacing="0" style={{"marginTop": "70px"}}>
            <tr>
              <td>
                <img src="https://www.awwex.com/assets/images/landingheader/header_02.png" width="81" height="74" alt=""/></td>
              <td>
                <a href={"https://www.awwex.com/"}>
                <img src="https://www.awwex.com/assets/images/landingheader/header_03.png" width="314" height="74" alt=""/></a></td>
              <td>
                <img src="https://www.awwex.com/assets/images/landingheader/header_04.png" width="621" height="74" alt=""/></td>
              <td>
                <a
                  href="https://www.awwex.com/signup"
                  style={recordimageLinkStyle}
                  onMouseEnter={() => setIsHoveredRecord(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredRecord(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>
              <td>
                <a
                  href="https://www.awwex.com/signin"
                  style={signimageLinkStyle}
                  onMouseEnter={() => setIsHoveredSign(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredSign(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>
              <td>
                <a
                  target={"_blank"}
                  href="https://www.facebook.com/assetgli/"
                  style={facebookimageLinkStyle}
                  onMouseEnter={() => setIsHoveredFacebook(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredFacebook(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>
              <td>
                <a
                  target={"_blank"}
                  href="https://www.instagram.com/assetworldwideexpress/"
                  style={instagramimageLinkStyle}
                  onMouseEnter={() => setIsHoveredInstagram(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredInstagram(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>
              <td>
                <a
                  target={"_blank"}
                  href="https://x.com/AssetGli"
                  style={ximageLinkStyle}
                  onMouseEnter={() => setIsHoveredX(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredX(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>


              <td>
                <a
                  target={"_blank"}
                  href="https://www.linkedin.com/company/assetworldwideexpress/posts/?feedView=all"
                  style={linkedinimageLinkStyle}
                  onMouseEnter={() => setIsHoveredLinkedin(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredLinkedin(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>

              <td>
                <a
                  target={"_blank"}
                  href="https://api.whatsapp.com/send/?phone=905327333777&text=Merhaba%21&type=phone_number&app_absent=0"
                  style={whatsappimageLinkStyle}
                  onMouseEnter={() => setIsHoveredWhatsapp(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredWhatsapp(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>

              <td>
                <a
                  href="https://www.awwex.com/"
                  style={languageimageLinkStyle}
                  onMouseEnter={() => setIsHoveredLanguage(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredLanguage(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>
              <td>
                <img src="https://www.awwex.com/assets/images/landingheader/header_13.png" width="103" height="74" alt=""/></td>
            </tr>
            <tr>
              <td colSpan="12">
                <img src="https://www.awwex.com/assets/images/landingheader/header_14.png" width="1920" height="16" alt=""/></td>
            </tr>
            <tr>
              <td colSpan={12}>
                <Form form={form} component={false}>
                <table id="Table_01" width="1921" border="0" cellPadding="0" cellSpacing="0">
                  <tr>
                    <td rowSpan="14">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_02.png" width="960" height="572" alt=""/></td>
                    <td colSpan="13">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_03.png" width="960" height="38" alt=""/></td>
                  </tr>
                  <tr>
                    <td rowSpan="13">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_04.png" style={{"marginTop":"-5px"}} width="37" height="534" alt=""/></td>
                    <td colSpan="11">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_05.png" width="805" height="28" alt=""/></td>
                    <td rowSpan="13">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_06.png" style={{"marginTop":"-5px"}} width="118" height="534" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="1" height="28" alt=""/></td>
                  </tr>
                  <tr>
                    <td colSpan="2" rowSpan="10">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_07.png" width="25" height="375" alt=""/></td>
                    <td colSpan="5">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_08.png" width="223" height="57" alt=""/></td>
                    <td colSpan="4" rowSpan="2">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_09.png" width="557" height="74" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="1" height="57" alt=""/></td>
                  </tr>
                  <tr>
                    <td colSpan="5">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_10.png" width="223" height="17" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="1" height="17" alt=""/></td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_11.png" width="177" height="48" alt=""/></td>
                    <td colSpan="2" rowSpan="2">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_12.png" width="29" height="69" alt=""/></td>
                    <td colSpan="3">
                      <Form.Item
                        name="gonderitipi"
                        label=""
                      >
                        <Radio.Group>
                          <Radio value="2"><span style={{fontSize:"xx-large", color:"white"}}>Paket</span></Radio>
                          <Radio value="1"><span style={{fontSize:"xx-large", color:"white"}}>Doküman</span></Radio>
                        </Radio.Group>
                      </Form.Item>
                    </td>
                    <td colSpan="2" rowSpan="4">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_14.png" width="51" height="151" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="1" height="48" alt=""/></td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_15.png" width="177" height="21" alt=""/></td>
                    <td colSpan="3">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_16.png" width="523" height="21" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="1" height="21" alt=""/></td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_17.png" width="189" height="57" alt=""/></td>
                    <td rowSpan="6">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_18.png" width="17" height="232" alt=""/></td>
                    <td colSpan="2">
                      <Form.Item
                        name="pakettipi"
                        label=""
                      >
                        <Radio.Group>
                          <Radio value="1"><span style={{fontSize:"xx-large", color:"white"}}>Koli</span></Radio>
                          <Radio value="2"><span style={{fontSize:"xx-large", color:"white"}}>Pak</span></Radio>
                        </Radio.Group>
                      </Form.Item>
                    </td>
                    <td rowSpan="2">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_20.png" width="10" height="82" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="1" height="57" alt=""/></td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_21.png" width="189" height="25" alt=""/></td>
                    <td colSpan="2">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_22.png" width="513" height="25" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="1" height="25" alt=""/></td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_23.png" width="189" height="55" alt=""/></td>
                    <td colSpan="4" rowSpan="2">
                      <Form.Item
                        name="ulkeler"
                        label=""
                      >
                        <Select
                          showSearch
                          placeholder="Seçiniz"
                          options={countryData}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                            style={{
                            width: 400,
                            marginTop: -30,
                            marginLeft: 0,
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Hafif gölge efekti
                            color: "black",
                          }}
                        >
                        </Select>
                      </Form.Item>
                    </td>
                    <td rowSpan="5">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_25.png" width="19" height="233" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="1" height="55" alt=""/></td>
                  </tr>
                  <tr>
                    <td colSpan="3" rowSpan="2">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_26.png" width="189" height="15" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="1" height="6" alt=""/></td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                    </td>
                    <td>
                    </td>
                  </tr>
                  <tr>
                    <td >
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_28.png" width="9" height="80" alt=""/></td>
                    <td colSpan="2">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_29.png" width="180" height="80" alt=""/></td>
                    <td colSpan="4">
                      <Form.Item label="" name={"postakodu"} rules={[{ required: true }]}>
                        <Input placeholder="Posta Kodu"  style={{ width: 200 }}/>
                      </Form.Item>
                    </td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="1" height="80" alt=""/></td>
                  </tr>
                  <tr>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_31.png" width="13" height="83" alt=""/>
                    </td>
                    <td colSpan="9">
                      <a
                        href="#"
                        onClick={async ()=>await getexternalPageRequest()}
                        style={pricingsubmitimageLinkStyle}
                        onMouseEnter={() => setIsHoveredPricingSubmit(true)} // Hover durumu aktif
                        onMouseLeave={() => setIsHoveredPricingSubmit(false)} // Hover durumu devre dışı
                      >
                        {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                      </a>
                    </td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="1" height="83" alt=""/></td>
                  </tr>
                  <tr>
                    <td colSpan="11">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_33.png" style={{"marginTop": "-10px"}} width="805" height="48" alt=""/>
                    </td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="1" height="48" alt=""/></td>
                  </tr>
                  <tr>
                  <td colSpan="14">
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_34.png" width="1920" height="200" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="1" height="200" alt=""/></td>
                  </tr>
                  <tr>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="960" height="1" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="37" height="1" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="13" height="1" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="12" height="1" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="9" height="1" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="168" height="1" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="12" height="1" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="17" height="1" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="17" height="1" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="496" height="1" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="10" height="1" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="32" height="1" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="19" height="1" alt=""/></td>
                    <td>
                      <img src="https://www.awwex.com/assets/images/pricingcalculate/spacer.gif" width="118" height="1" alt=""/></td>
                    <td></td>
                  </tr>
                </table>
                </Form>
              </td>
            </tr>

          </table>
        </div>
      </header>
      <body>
        <Modal title="Fiyat Hesaplama" width={1800} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <PricingGet externalPageRequest={externalPageRequest}></PricingGet>
        </Modal>
      </body>
      <footer>
        test
      </footer>
    </div>
  )
    ;
}

export default AwwexLandingPageHeader;
