import React from "react";
import privilegesImage from "../images/privileges/privileges.png";
import privilegesImage2 from "../images/privileges/privileges-2.png";
import privilegesImage3 from "../images/privileges/privileges-3.png";
import privilegesImage4 from "../images/privileges/privileges-4.png";
import privilegesImage5 from "../images/privileges/privileges-5.png";
import privilegesImage6 from "../images/privileges/privileges-6.png";
import globalServicesImage from "../images/privileges/global-services.png";
import privilegesImage7 from "../images/privileges/privileges-7.png";
import "../css/privilegeslanding.css";
import { Helmet } from "react-helmet";

const AwwexLandingPrivileges = () => {
  return (
    <>
      <Helmet>
        <title>Ayrıcalıklar - Awwex </title>
      </Helmet>
      <div>
        <section className="page-title" id="about">
          <div className="image-container">
            <img src={privilegesImage} alt="Privileges Image" />
            <div className="overlay"></div>
            <h2 className="title">AYRICALIKLAR</h2>
          </div>
        </section>
        <br />

        <section className="banner-right">
          <div className="banner-content">
            <div className="banner-image">
              <img src={privilegesImage2} alt="Services Image" />
            </div>
            <div className="banner-text">
              <h1>Tek Tıkla Uçtan Uca 200+ Ülkeye Teslİmat</h1>
              <br />
              <p>
                Asset Worldwide Express; AwwEx Global Hızlı Kurye Yazılımı ile
                tek tıkla bireysel koli, paket ve e-ihracat gönderileriniz için
                global servisleri ile daha düşük taşıma maliyetleri ve takip
                çözümleri sunarak dünyanın 200+ fazla ülkesine hızlı, kolay,
                ekonomik kargo hizmeti sunar.
                <br />
                Gönderinizin adresinizden alınıp alıcısına ulaşana kadar olana
                kadar olan yolculuğunu AwwEx ile takip edebilir her hangi bir
                sorunuzda 24/7 Müşteri Destek Ekine ulaşabilirsiniz.
              </p>
            </div>
          </div>
        </section>
        <hr />
        <br />
        <section className="banner-left">
          <div className="banner-content">
            <div className="banner-image">
              <img src={privilegesImage3} alt="Services Image" />
            </div>
            <div className="banner-text">
              <h1>Ekonomİk Fİyatlandırma Avantajı</h1>
              <br />
              <p>
                Asset Worldwide Express e-ticaret satışlarının yoğun olduğu
                ülkelerde (Avrupa, Amerika, Ortadoğu) kurduğu global lojistik
                servis ağlarını kullanarak lojistik maliyetlerini düşürür ve
                müşterilerine en ekonomik uluslararası gönderi fiyatlarını
                sunar.
              </p>
            </div>
          </div>
        </section>
        <hr />
        <section className="banner-right">
          <div className="banner-content">
            <div className="banner-image">
              <img src={privilegesImage4} alt="Services Image" />
            </div>
            <div className="banner-text">
              <h1>21 Yıllık Lojistik Uzmanlığı Ürün Güvenlİğİ</h1>
              <br />
              <p>
                Lojistik faaliyetler; farklı bileşenlerin, farklı kişi,
                organizasyon, ekip ve ekipmanların bir araya geldiği uzmanlık
                gerektiren karmaşık süreçtir. Üstelik prosedürler ve yasal
                işlemler çoğunlukla ülkeden ülkeye farklılık gösterir.
                <br />
                Asset Worldwide Express 21 yıllık entegre lojistik ve gümrük
                hizmetleri uzmanlığı ile operasyonel süreçlerde ihtiyaç duyulan
                ve taşınan ürünün / yükün bir noktadan diğerine güvenle
                ulaştırılması esnasında doğabilecek riskleri minimuma
                indirilmesi için yapılması gereken taşımacılık sigortası,
                sözleşme ve genel güvenlik tedbirlerini profesyonellikle yürütür
                ve kullanıcıları için ürün güvenliği sağlar.
              </p>
            </div>
          </div>
        </section>
        <hr />
        <br />
        <section className="banner-left">
          <div className="banner-content">
            <div className="banner-image">
              <img src={privilegesImage5} alt="Services Image" />
            </div>
            <div className="banner-text">
              <h1>Uluslararası Güvenli Ödeme Sİstemlerİ</h1>
              <br />
              <p>
                Uluslararası ödeme kuruluşlarıyla yapılan anlaşmalar
                çerçevesinde, AwwEx Global Hızlı Kurye Yazılımı üzerinden
                yapılan tüm ödemeler, güvenli bir şekilde
                gerçekleştirilmektedir. Sistemlerimiz yazılım ekibimiz
                tarafından düzenli güvenlik testlerinden geçirilir.
              </p>
            </div>
          </div>
        </section>
        <hr />
        <br />
        <section className="banner-right">
          <div className="banner-content">
            <div className="banner-image">
              <img src={privilegesImage6} alt="Services Image" />
            </div>
            <div className="banner-text">
              <h1>Avrupa Depo Çözümlerİ İle Global Servİsler</h1>
              <br />
              <p>
                5000 m²’lik depolama alanına sahip Köln Lojistik Üssü üzerinden
                Köln’de Almanya üzerinden tüm Avrupa, Amerika ve İngiltere’ye
                olmak üzere Asset Global Servis hizmetleri verilmektedir.
                Detaylı bilgiler için Müşteri Deneyim hattımızı arayabilirsiniz.
              </p>
            </div>
          </div>
        </section>
        <hr />
        <br />
        <section className="global-services">
          <h1 className="global-services__title">GLOBAL SERVİSLER</h1>

          <div className="global-services__content">
            <div className="global-services__service-box">
              <h2 className="global-services__service-title">Asset Ekonomi</h2>
              <p className="global-services__service-text">
                Uçtan uca Asset Global servisi ile günlük Almanya uçuşlarımızla
                Köln Asset Lojistik Merkezimizden tüm Avrupa ülkelerine hızlı ve
                düşük maliyetli taşımacılık hizmeti sunuyoruz. Sektördeki
                farkımızı, sunduğumuz hız ve uygun fiyatlarla ortaya koyuyoruz.
              </p>
            </div>

            <div className="global-services__divider"></div>
            <div className="global-services__service-box">
              <h2 className="global-services__service-title">Asset Express</h2>
              <p className="global-services__service-text">
                Firmadan firmaya veya online ticaret gönderilerinizi kapınızdan
                alıyor, dünyanın her yerine hızlı teslimatlar yaparak
                satışlarınızın başarıya ulaşmasına katkı sağlıyoruz.
              </p>
            </div>
          </div>

          <div className="global-services__image">
            <img
              src={globalServicesImage}
              alt="Global Servisler Uçak Görseli"
            />
          </div>

          <div className="global-services__content">
            <div className="global-services__service-box">
              <h2 className="global-services__service-title">Asset Mena</h2>
              <p className="global-services__service-text">
                Firmadan firmaya veya online ticaret gönderilerinizi kapınızdan
                alıyor, Dubai, Suudi Arabistan, Katar, Ürdün ve Kuveyt bölgesine
                ekonomik ve hızlı teslimatlar yaparak maliyetlerinizi
                düşürüyoruz.
              </p>
            </div>

            <div className="global-services__divider"></div>

            <div className="global-services__service-box">
              <h2 className="global-services__service-title">Asset Amerika</h2>
              <p className="global-services__service-text">
                Asset Global servisi ile firmadan firmaya veya online ticaret
                gönderilerinizi kapınızdan alıyor, Amerika'ya ekonomik ve hızlı
                teslimatlar yaparak zaman ve maliyet avantajı sağlıyoruz.
              </p>
            </div>
          </div>
        </section>
        <hr />
        <section className="banner banner-left">
          <div className="banner-content">
            <div className="banner-image">
              <img src={privilegesImage7} alt="Services Image" />
            </div>
            <div className="banner-text">
              <h1>24/7 Destek</h1>
              <p>
                Müşteri Deneyim Ekibimiz, telefon hattı, Whatsapp hattı ve
                e-posta adresimiz üzerinden 24/7 destek sağlamaktadır.
                <br />
                <b>E-posta:</b> info@awwex.com
                <br />
                <b>Whatsapp:</b> 0532 7 333 777
                <br />
                <b>Telefon:</b> 444 5 127
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AwwexLandingPrivileges;
