import React, { useRef, useState, useEffect } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Divider,
  Radio,
  Col,
  Row,
  Card,
  Select,
  Space,
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
} from "antd";

function DynamicFieldForPiece(props) {
  const [pieceCreateCount, setPieceCreateCount] = useState(0);
  const btnaddref = useRef(0);

  useEffect(() => {
    const fields = props.form?.getFieldValue('fields');
    
    if ((!fields || fields.length === 0) && props.form) {
      props.form.setFieldsValue({
        fields: [{}]
      });
    }
  }, [props.form]);

  return (
    <Form.List name="fields">
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Divider>Paket {index + 1}</Divider>

                <Row>
                  <Col style={{ width: 300 }}>
                    <Form.Item
                      name={[index, "PieceCount"]}
                      label="Paket Adedi"
                      rules={[
                        { required: true, message: "Paket adedi gerekli!" },
                        {
                          validator: (_, value) =>
                            Number.isInteger(value)
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error("Lütfen bir tam sayı giriniz!")
                                ),
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        step={1}
                        onChange={() => props.changeFormData()}
                        formatter={(value) => `${value}`.replace(/\.\d*/, "")}
                        parser={(value) => parseInt(value || "0", 10)}
                      />
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item>
                      <span>
                        <nobr>ExBxY (Cm):</nobr>
                      </span>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name={[index, "PieceWidth"]}
                      rules={[{ required: true }]}
                      
                    >
                      <InputNumber
                        min={0}
                        onChange={() => props.changeFormData()}
                        parser={(value) => value?.replace(",", ".")}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name={[index, "PieceLength"]}
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        min={0}
                        onChange={() => props.changeFormData()}
                        parser={(value) => value?.replace(",", ".")}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name={[index, "PieceHeight"]}
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        min={0}
                        onChange={() => props.changeFormData()}
                        parser={(value) => value?.replace(",", ".")}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <span>
                        <nobr>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hacimsel Ağırlık (Kg):  
                        </nobr>
                      </span>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name={[index, "PieceVolumeWeight"]}
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        min={0}
                        onChange={() => props.changeFormData()}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <span>
                        <nobr>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ağırlık (Kg):
                        </nobr>
                      </span>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name={[index, "PieceWeight"]}
                      rules={[
                        { required: true, message: "Lütfen ağırlık giriniz!" },
                        {
                          validator: (_, value) => {
                            if (value === undefined || value >= 0) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Geçerli bir değer giriniz!")
                            );
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        step={0.1}
                        parser={(value) => value?.replace(",", ".")}
                        onChange={() => props.changeFormData()}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {fields.length > 1 ? (
                  <Button
                    type="danger"
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                    icon={<MinusCircleOutlined />}
                  >
                    Yukarıdaki parçayı sil
                  </Button>
                ) : null}
              </div>
            ))}
            <Divider />
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "60%" }}
                ref={btnaddref}
              >
                <PlusOutlined /> Parça Ekle
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
}

export default DynamicFieldForPiece;
