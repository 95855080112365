import React, { useEffect, useState } from "react";
import { Alert, Button } from "antd";
import { useHistory } from "react-router-dom";
import { CallerGetDocumentFileListByCompany } from "../../datalayer/document/callerdocumentwxcontroller";

export default function VerificationAlert(props) {
  const history = useHistory();
  const { company, isCustomerUser } = props;
  const [documentNote, setDocumentNote] = useState(null);
  const [isDocumentsInvalid, setIsDocumentsInvalid] = useState(false); 
  const [visible, setVisible] = useState(true); 

  const handleVerification = () => {
    history.push("/definitions/company");
  };

  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    const fetchDocument = async () => {
      if (company?.companyStatusTypeId !== 1) {
        return;
      }

      const rq1 = {
        PageNumber: 1,
        PageSize: 100,
        Id: 0,
        CompanyID: localStorage.getItem("companyID"),
        ApprovalStatus: -1,
        isDeleted: 0,
      };

      try {
        const dt = await CallerGetDocumentFileListByCompany(rq1);
        if (dt?.items?.length > 0) {
          let allItemsValid = true;
          let hasInvalidDocuments = false;

          let foundTypes = new Set();

          dt.items.forEach(item => {
            if (item.isDdeleted === 0 && (item.approvalStatus === 2 || item.approvalStatus==3)) {
              foundTypes.add(item.dokumentType);
            } else {
              allItemsValid = false;
              hasInvalidDocuments = true;
            }
          });

          if (foundTypes.has(1) && foundTypes.has(2) && foundTypes.has(3)) {
            if (allItemsValid) {
              setDocumentNote(true);
              setIsDocumentsInvalid(false);
            } else {
              setDocumentNote(false);
              setIsDocumentsInvalid(hasInvalidDocuments);
            }
          } else {
            allItemsValid = false;
            setDocumentNote(false);
            setIsDocumentsInvalid(true);
          }
        } else {
          setDocumentNote(false);
        }
      } catch (error) {
        console.error("Doküman notu alınırken hata oluştu:", error);
      }
    };

    fetchDocument();
  }, [company]);

  if (company?.companyStatusTypeId !== 1 || !visible) {
    return null;  
  }

  if (documentNote === null) {
    return null;
  }

  if (isDocumentsInvalid) {
    return (
      <Alert
        message="Eksik veya Onaylanmamış Doküman"
        description="Hesabınız için gerekli bazı dokümanlar eksik veya onaylanmamış durumda. Lütfen dokümanları kontrol ediniz."
        type="error"
        showIcon
        style={{ margin: "16px" }}
        closable
        onClose={handleClose}
        action={
          <Button
            type="primary"
            style={{ marginTop: "10px" }}
            onClick={handleVerification}
          >
            Dokümanları Kontrol Et
          </Button>
        }
      />
    );
  }

  if (documentNote) {
    return (
      <Alert
        message="Hesap Doğrulama Bekleniyor"
        description="Şu anda hesabınız kontrol sürecindedir. Bu süreçte herhangi bir işlem yapmanıza gerek yoktur."
        type="warning"
        showIcon
        style={{ margin: "16px" }}
        closable
        onClose={handleClose}
        action={
          <Button
            type="primary"
            style={{ marginTop: "10px" }}
            onClick={handleVerification}
          >
            Kontrol Et
          </Button>
        }
      />
    );
  }

  return (
    <Alert
      message="Hesap Doğrulama"
      description="Awwex'i kullanmaya başlamak için lütfen hesabınızı doğrulayın."
      type="error"
      showIcon
      style={{ margin: "16px" }}
      closable
      onClose={handleClose}
      action={
        <Button
          type="primary"
          style={{ marginTop: "10px" }}
          onClick={handleVerification}
        >
          Hesabı Doğrula
        </Button>
      }
    />
  );
}
