import { getAPICall, postAPICall, putAPICall, deleteAPICall } from '../datalayer';
import getMyMessage from "../../util/RestReturnCatchForException";

async function CallerPricingGetController(reqTempItem){
    try {
        const response = await postAPICall('/portalbend/Pricing/PricingGet', reqTempItem);
        return response.data;
    }
    catch(error){
        let jsonRtrn = {}
        try {
            jsonRtrn = getMyMessage(error);
        } catch (error) {
            jsonRtrn.ReturnCode = 500;
            jsonRtrn.ReturnText = error.response;
        }
        return jsonRtrn;
    }
}

async function CallerGetSegmentProfitRatesController() {
    try {
        const response = await getAPICall('/portalbend/Pricing/GetSegmentProfitRates');
        return response.data;
    } catch (error) {
        return getMyMessage(error);
    }
}

async function CallerUpdateSegmentProfitRateController(request) {
    try {
        const response = await postAPICall('/portalbend/Pricing/UpdateSegmentProfitRate', request);
        return response.data;
    } catch (error) {
        return getMyMessage(error);
    }
}

async function CallerCreateSegmentController(request) {
    try {
        const response = await postAPICall('/portalbend/Pricing/CreateSegment', request);
        return response.data;
    } catch (error) {
        return getMyMessage(error);
    }
}

async function CallerUpdateSegmentController(request) {
    try {
        const response = await postAPICall('/portalbend/Pricing/UpdateSegment', request);
        return response.data;
    } catch (error) {
        return getMyMessage(error);
    }
}

async function CallerGetPriceListController(request) {
    try {
        const response = await postAPICall('/portalbend/Pricing/GetPriceList', request);
        return response.data;
    } catch (error) {
        return getMyMessage(error);
    }
}

export {
    CallerPricingGetController,
    CallerGetSegmentProfitRatesController,
    CallerUpdateSegmentProfitRateController,
    CallerCreateSegmentController,
    CallerUpdateSegmentController,
    CallerGetPriceListController
};