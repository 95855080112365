import React, { useEffect, useState } from "react";
import "../css/aboutlanding.css";
import fedex from "../images/firms2/fedex.png";
import etsy from "../images/firms2/etsy.png";
import ptt from "../images/firms2/ptt.png";
import amazon from "../images/firms2/amazon.png";
import ticimax from "../images/firms2/ticimax.png";
import ideasoft from "../images/firms2/ideasoft.png";
import ebay from "../images/firms2/ebay.png";
import wallmart from "../images/firms2/wallmart.png";
import tsoft from "../images/firms2/tsoft.png";
import tnt from "../images/firms2/tnt.png";
import parasut from "../images/firms2/parasut.png";
import dhl from "../images/firms2/dhl.png";
import video1 from "../slides/Slide 1.mp4";
import video2 from "../slides/Slide 2.mp4";
import video3 from "../slides/Slide 3.mp4";
import humans_1 from "../images/humans/humans_1.png";
import humans_2 from "../images/humans/humans_2.png";
import humans_3 from "../images/humans/humans_3.png";
import humans_4 from "../images/humans/humans_4.png";
import humans_5 from "../images/humans/humans_5.png";
import humans_6 from "../images/humans/humans_6.png";
import PricingGet from "../../routes/pricing/pricingget";
import Tracking from "../../routes/shipment/tracking";
import { Modal, Form, Input, Select, Radio, Button,Typography, Divider } from "antd";
import { CallerGetCountryListController } from "../../datalayer/definations/callerdefinationcontroller";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
const { Title, Text } = Typography;

const brands = [
  { src: fedex, alt: "FedEx" },
  { src: etsy, alt: "Etsy" },
  { src: ptt, alt: "PTT" },
  { src: amazon, alt: "Amazon" },
  { src: ticimax, alt: "Ticimax" },
  { src: ideasoft, alt: "IdeaSoft" },
  { src: ebay, alt: "Ebay" },
  { src: wallmart, alt: "Wallmart" },
  { src: tsoft, alt: "TSoft" },
  { src: tnt, alt: "TNT" },
  { src: parasut, alt: "Parasut" },
  { src: dhl, alt: "DHL" },
];

const humans = [
  { text: "Tek Tıkla Uçtan Uca 200 Ülkeye Teslimat", img: humans_1 },
  { text: "Ekonomik Fiyatlandırma Avantajı", img: humans_2 },
  { text: "21 Yıllık Lojistik Uzmanlığı ile Ürün Güvenliği", img: humans_3 },
  { text: "Avrupa Depo Çözümleri ile Global Servisler", img: humans_4 },
  { text: "Uluslararası Güvenli Ödeme Sistemleri", img: humans_5 },
  { text: "24/7 Uzman Destek", img: humans_6 },
];
const videos = [video1, video2, video3];

const AwwexLandingContent = () => {
  const [pricingForm] = Form.useForm();
  const [trackingForm] = Form.useForm();

  const videos = [video1, video2, video3];
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const [isTrackingModalOpen, setIsTrackingModalOpen] = useState(false);
  const [externalPageRequestPricing, setExternalPageRequestPricing] =
    useState(false);
  const [externalPageRequestTracking, setExternalPageRequestTracking] =
    useState(false);

  const [currentVideo, setCurrentVideo] = useState(0);
  const videoRef = React.useRef(null);

  const [countryData, setCountryData] = useState([]);

  const [isCaptchaModalOpen, setIsCaptchaModalOpen] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const fetchCountries = async (value) => {
    let request = { SearchText: value };
    let response = await CallerGetCountryListController(request);
    if (response) {
      const options = response.map((country) => ({
        key: country.id,
        value: country.code,
        label: country.name,
      }));
      setCountryData(options);
    }
  };

  const showPricingModal = () => {
    setIsPricingModalOpen(true);
  };

  const handleCancelPricing = () => {
    setIsPricingModalOpen(false);
  };

  const showTrackingModal = () => {
    setIsTrackingModalOpen(true);
  };

  const handleCancelTracking = () => {
    setIsTrackingModalOpen(false);
    setIsCaptchaModalOpen(false);
  };

  const onCaptchaChange = async (value) => {
    if (value) {
      setCaptchaVerified(true);
      setIsCaptchaModalOpen(false);
      await getexternalPageRequestTracking();
    }
  };

  const handleTracking = async () => {
    debugger;
    if (captchaVerified) {
      await getexternalPageRequestTracking();
    } else {
      setIsCaptchaModalOpen(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentVideo((prevVideo) => (prevVideo + 1) % videos.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load(); 
      videoRef.current.play(); 
    }
  }, [currentVideo]);

  useEffect(async () => {
    await fetchCountries();
  }, []);

  const getVideoSource = () => {
    return videos[currentVideo - 1];
  };

  async function getexternalPageRequestPricing() {
    const values = await pricingForm.getFieldsValue();
    let epr = {
      packagetype: values.pakettipi,
      posttype: values.gonderitipi,
      country: values.ulkeler,
      zipcode: values.postakodu,
    };
    debugger;
    setExternalPageRequestPricing(epr);
    showPricingModal();
  }

  async function getexternalPageRequestTracking() {
    const values = await trackingForm.getFieldsValue();
    let epr = {
      assetreference: values.awwexreference,
    };
    debugger;
    setExternalPageRequestTracking(epr);
    showTrackingModal();
  }




  return (
    <>
      <section className="video-background">
        <video className="background-video" autoPlay muted ref={videoRef}>
          <source src={videos[currentVideo]} type="video/mp4" />
          Tarayıcınız video etiketini desteklemiyor.
        </video>

        <div className="content">
          <div className="content-text">
            <h3 className="content-text-1">Siz Yeter Ki Üretin</h3>
            <h3 className="content-text-2">#YANINIZDAYIZ</h3>
          </div>
          <div className="content-form">
            <Form
              form={pricingForm}
              component={false}
              style={{
                fontFamily: "NimbusSanL-Reg, sans-serif",
                fontSize: "16px",
                color: "white",
              }}
            >
              <h1 style={{ color: "white" }}>TEKLİF AL</h1>

              <Form.Item
                name="gonderitipi"
                label={
                  <span
                    style={{
                      color: "white",
                      fontSize: "20px",
                      fontFamily: "NimbusSanL-Reg, sans-serif",
                    }}
                  >
                    Gönderi Tipi:
                  </span>
                }
              >
                <Radio.Group
                  style={{ display: "inline-flex", alignItems: "center" }}
                >
                  <Radio
                    value="2"
                    style={{ marginRight: "8px", padding: "0 5px" }}
                  >
                    <span style={{ color: "white", fontSize: "20px" }}>
                      Paket
                    </span>
                  </Radio>
                  <Radio value="1" style={{ padding: "0 5px" }}>
                    <span style={{ color: "white", fontSize: "20px" }}>
                      Doküman
                    </span>
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <br />

              <Form.Item
                name="pakettipi"
                label={
                  <span
                    style={{
                      color: "white",
                      fontSize: "20px",
                      fontFamily: "NimbusSanL-Reg, sans-serif",
                    }}
                  >
                    Paket Tipi:
                  </span>
                }
              >
                <Radio.Group
                  style={{ display: "inline-flex", alignItems: "center" }}
                >
                  <Radio
                    value="1"
                    style={{ marginRight: "8px", padding: "0 5px" }}
                  >
                    <span style={{ color: "white", fontSize: "20px" }}>
                      Koli
                    </span>
                  </Radio>
                  <Radio value="2" style={{ padding: "0 5px" }}>
                    <span style={{ color: "white", fontSize: "20px" }}>
                      Pak
                    </span>
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <br />

              <Form.Item
                name="ulkeler"
                style={{ margin: 0 }}
                label={
                  <span
                    style={{
                      color: "white",
                      fontSize: "20px",
                      fontFamily: "NimbusSanL-Reg, sans-serif",
                    }}
                  >
                    Alıcı Ülkesi:
                  </span>
                }
              >
                <Select
                  showSearch
                  placeholder="Seçiniz"
                  options={countryData}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  style={{
                    width: "100%",
                    height: 40,
                    fontSize: "16px",
                    color: "black",
                    fontFamily: "NimbusSanL-Reg, sans-serif",
                  }}
                />
              </Form.Item>
              <br />
              <Form.Item
                name="postakodu"
                label={
                  <span
                    style={{
                      color: "white",
                      fontSize: "20px",
                      fontFamily: "NimbusSanL-Reg, sans-serif",
                    }}
                  >
                    Posta Kodu:
                  </span>
                }
                style={{ margin: 0 }}
              >
                <Input
                  placeholder="Posta Kodu"
                  style={{
                    width: "100%",
                    height: 40,
                    fontSize: "16px",
                    fontFamily: "NimbusSanL-Reg, sans-serif",
                  }}
                />
              </Form.Item>
              <button
                type="button"
                className="btn-calculate"
                onClick={async () => await getexternalPageRequestPricing()}
              >
                HESAPLA
              </button>
            </Form>
          </div>
        </div>
      </section>

      <section className="brands">
        <table>
          <tbody>
            {Array.from({ length: 2 }).map((_, rowIndex) => (
              <tr key={rowIndex}>
                {brands
                  .slice(rowIndex * 6, rowIndex * 6 + 6)
                  .map((brand, index) => (
                    <td key={index}>
                      <button>
                        <img src={brand.src} alt={brand.alt} />
                      </button>
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      <section className="find-reference">
        <div className="header-container">
          <h2>KARGONUZ NEREDE BİLİN</h2>
          <div className="input-container">
            <Form
              form={trackingForm}
              component={false}
              style={{
                fontFamily: "NimbusSanL-Reg, sans-serif",
                fontSize: "16px",
                color: "white",
              }}
            >
              <Form.Item name="awwexreference" style={{ margin: 0 }}>
                <Input
                  placeholder="Asset Referans ID"
                  style={{
                    width: "50vh",
                    height: 50,
                    fontSize: "16px",
                    fontFamily: "NimbusSanL-Reg, sans-serif",
                  }}
                />
              </Form.Item>
            </Form>
            <button
              onClick={handleTracking}
            >
              SORGULA
            </button>
          </div>
        </div>
      </section>

      <section className="humans">
        <div className="humans-content">
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="content">
                    <Link to="/privileges" className="redirect-button">
                      <button>
                        <p>Tek Tıkla Uçtan Uca 200 Ülkeye Teslimat</p>
                        <img src={humans_1} alt="Humans 1" />
                      </button>
                    </Link>
                  </div>
                </td>
                <td>
                  <div className="content">
                    <Link to="/privileges" className="redirect-button">
                      <button>
                        <img src={humans_2} alt="Humans 2" />
                        <p>Ekonomik Fiyatlandırma Avantajı</p>
                      </button>
                    </Link>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="content">
                    <Link to="/privileges" className="redirect-button">
                      <button>
                        <p>21 Yıllık Lojistik Uzmanlığı ile Ürün Güvenliği</p>
                        <img src={humans_3} alt="Humans 3" />
                      </button>
                    </Link>
                  </div>
                </td>
                <td>
                  <div className="content">
                    <Link to="/privileges" className="redirect-button">
                      <button>
                        <img src={humans_4} alt="Humans 4" />
                        <p>Avrupa Depo Çözümleri ile Global Servisler</p>
                      </button>
                    </Link>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="content">
                    <Link to="/privileges" className="redirect-button">
                      <button>
                        <p>Uluslararası Güvenli Ödeme Sistemleri</p>
                        <img src={humans_5} alt="Humans 5" />
                      </button>
                    </Link>
                  </div>
                </td>
                <td>
                  <div className="content">
                    <Link to="/privileges" className="redirect-button">
                      <button>
                        <img src={humans_6} alt="Humans 6" />
                        <p>24/7 Uzman Destek</p>
                      </button>
                    </Link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <Modal
        title="Fiyat Hesaplama"
        width={1800}
        open={isPricingModalOpen}
        onCancel={handleCancelPricing}
        footer={[
          <Button key="cancel" onClick={handleCancelPricing}>
            Kapat
          </Button>,
        ]}
      >
        <PricingGet
          externalPageRequest={externalPageRequestPricing}
        ></PricingGet>
      </Modal>

      <Modal
        title="Gönderi Sorgulama"
        width={1800}
        open={isTrackingModalOpen}
        onCancel={handleCancelTracking}
        footer={[
          <Button key="cancel" onClick={handleCancelTracking}>
            Kapat
          </Button>,
        ]}
      >
        <Tracking externalPageRequest={externalPageRequestTracking}></Tracking>
      </Modal>

      <Modal
      title={null} // Başlığı kaldırıyoruz, özel başlık ekleniyor
      open={isCaptchaModalOpen}
      onCancel={handleCancelTracking}
      footer={null} // Footer'ı kaldırıyoruz
      centered // Modalı ekranda ortalıyoruz
    >
      <div style={{ textAlign: "center", padding: "20px" }}>
        <Title level={4}>Güvenlik Doğrulaması</Title>
        <Text>
          Devam etmek için aşağıdaki reCAPTCHA doğrulamasını tamamlayınız. Bu işlem, hesabınızı ve sistemimizi güvende tutmamıza yardımcı olur.
        </Text>
        <Divider />
        <div style={{ marginTop: "16px", display: "flex", justifyContent: "center" }}>
          <ReCAPTCHA
            sitekey="6LexsbAqAAAAACacU2yXNU7_B5SSM7pLdavaJKeU" // Google reCAPTCHA anahtarını buraya ekleyin
            onChange={onCaptchaChange}
          />
        </div>
      </div>
    </Modal>
    </>
  );
};

export default AwwexLandingContent;
