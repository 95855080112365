import React from "react";
import logo from "../images/icons/logo.png";
import facebookIcon from "../images/icons/facebook.png";
import instagramIcon from "../images/icons/instagram.png";
import twitterIcon from "../images/icons/x.png";
import linkedinIcon from "../images/icons/linkedin.png";
import whatsappIcon from "../images/icons/whatsapp.png";
import aboutImage from "../images/about/about.png";
import aboutImage2 from "../images/about/about-2.png";
import aboutImage3 from "../images/about/about-3.png";
import servicesImage from "../images/services/services-2.png";
import "../css/aboutlanding.css";
import { Helmet } from "react-helmet";

const AwwexLandingAbout = () => {
  const toggleMenu = () => {};

  return (
    <>
      <Helmet>
        <title>Hakkımızda - Awwex </title>
      </Helmet>
      <div>
        <section className="page-title" id="about">
          <div className="image-container">
            <img src={aboutImage} alt="About Image" />
            <div className="overlay"></div>
            <h2 className="title">HAKKIMIZDA</h2>
          </div>
        </section>

        <section className="banner-right">
          <div className="banner-content">
            <div className="banner-image">
              <img src={aboutImage2} alt="Services Image" />
            </div>
            <div className="banner-text">
              <h1>Asset Worldwıde Express Hakkında</h1>
              <br />
              <p>
                Asset Worldwide Express, 2020 yılında Asset GLİ çatısı altında
                entegre lojistik hizmetlerinin son ayağı olarak kurulan bir
                ekspres kurye servisidir. Dünya çapında 200'den fazla ülkeye
                teslimat gerçekleştiren global bir lojistik ve gümrük şirketi
                olarak, kurumsal veya bireysel, KOBİ veya e-ticaret
                pazaryerlerine B2C ve B2B ekspres kurye hizmetleri ve tedarik
                zinciri çözümleri sunar.
              </p>
            </div>
          </div>
        </section>

        <section className="about-awwex">
          <h1>AWWEX HAKKINDA</h1>
          <p>
            21 yıldır Türkiye’nin deniz, hava ve kara olmak üzere global
            lojistik entegrasyonunda uzman markası Asset GLİ çatısı altında 2020
            kurulan Asset World Express, geliştirdiği AwwEx Hızlı Kurye Yazılımı
            ile kurumsal veya bireysel, KOBİ veya e-ticaret pazaryerlerine B2C
            ve B2B e-ihracat kapsamında yurtdışına göndermek istediğiniz zarf,
            paket, hediye, koli, değerli eşya vb. gönderinizi uçtan uca, kapıdan
            kapıya dünyaya ulaşmasını sağlıyor.
          </p>
          <img src={aboutImage3} alt="About Image" />
          <p>
            Asset Worldwide Express; 5000 m²’lik depolama alanına sahip Köln
            Lojistik Üssü, pazar yerlerine özel entegrasyona sahip AwwEx global
            hızlı kurye entegrasyon yazılımı, global lojistik servis ağları ile
            uygun fiyat avantajı, günlük 6 uçuş ve 500+ fazla çalışan ile
            küresel lojistik üssü olma hedefindeki Türkiye’nin üreterek büyüyen
            mikro ihracatçılarının hızlı, kolay, ekonomik uluslararası kargo
            çözümleri ortağıdır.
          </p>
        </section>

        <section className="cargo-banner">
          <hr />
          <br />
          <h4>Asset Worldwide Express olarak</h4>
          <h1>HIZLI, KOLAY, EKONOMİK</h1>
          <h4>
            Uluslararası Kargo hizmetinde yeni esnek ve teknolojik çözümlerle
          </h4>
          <h3>#Yanınızdayız</h3>
          <br />
          <hr />
        </section>

        <section className="banner-left">
          <div className="banner-content">
            <div className="banner-image">
              <img src={servicesImage} alt="Services Image" />
            </div>
            <div className="banner-text">
              <h1>HİZMETLERİMİZ</h1>
              <br />
              <p>
                - BGB yetkisiyle Mikro İhracat
                <br />
                - Asset Express’in Global Servisleri ile maliyet avantajı
                (Avrupa, ABD, İngiltere, Dubai, Suudi Arabistan)
                <br />
                - E-ticaret Lojistiği (B2B-B2C)
                <br />
                - Fulfillment
                <br />
                - Amazon, Etsy, Ebay, Zalando gibi pazaryerleri teslimatları ve
                Amazon FBA
                <br />
                - Import One Stop Shop (IOSS) ve DDP Servisler
                <br />
                - E-ticaret iade lojistik süreçleri, Türkiye’ye geri dönüş
                <br />
                - Türkiye içinde 81 ilden adresten gönderi toplama
                <br />
                - API Entegrasyonları
                <br />
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AwwexLandingAbout;
