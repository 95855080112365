import React, { useState } from "react";
import logo from '../images/icons/logo.png';
import facebookIcon from '../images/icons/facebook-colored.png';
import instagramIcon from '../images/icons/instagram-colored.png';
import twitterIcon from '../images/icons/x-colored.png';
import linkedinIcon from '../images/icons/linkedin-colored.png';
import whatsappIcon from '../images/icons/whatsapp-colored.png';

function AwwexLandingHeader(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
      <a href="https://www.awwex.com" >
      <img src={logo} alt="Logo" />
        </a>
       
      </div>

      <div className="auth-links">
        <a href="https://www.awwex.com/signup" className="auth-link">
          KAYIT OL
        </a>
        <a href="https://www.awwex.com/signin" className="auth-link">
          OTURUM AÇ
        </a>
      </div>

      <div className="social-icons">
        <div className="social-icon">
          <a href="https://www.facebook.com/assetgli" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" />
          </a>
        </div>
        <div className="social-icon">
          <a href="https://www.instagram.com/assetworldwideexpress" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" />
          </a>
        </div>
        <div className="social-icon">
          <a href="https://www.twitter.com/AssetGli" target="_blank" rel="noopener noreferrer">
            <img src={twitterIcon} alt="Twitter" />
          </a>
        </div>
        <div className="social-icon">
          <a href="https://www.linkedin.com/company/assetworldwideexpress/" target="_blank" rel="noopener noreferrer">
            <img src={linkedinIcon} alt="LinkedIn" />
          </a>
        </div>
        <div className="social-icon">
          <a href="https://api.whatsapp.com/send/?phone=905327333777&text=Merhaba%21&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
            <img src={whatsappIcon} alt="WhatsApp" />
          </a>
        </div>
      </div>

      {/* Hamburger Menü */}
      <div className="hamburger-menu" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Mobil Menü */}
      <nav className={`mobile-nav ${isMenuOpen ? "active" : ""}`}>
        <ul>
          <li>
            <a href="https://www.awwex.com/signin">OTURUM AÇ</a>
          </li>
          <li>
            <a href="https://www.awwex.com/signup">KAYDOL</a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default AwwexLandingHeader;
