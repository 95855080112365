import React, { useState, useEffect } from "react";
import { Select, Button, Checkbox, Form, Input, Modal } from "antd";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignUp,
  userTwitterSignIn,
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import { message } from "antd/lib/index";
import CircularProgress from "../components/CircularProgress";
import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";
import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
import GithubOutlined from "@ant-design/icons/lib/icons/GithubOutlined";
import TwitterOutlined from "@ant-design/icons/lib/icons/TwitterOutlined";
import { CallerSendEmailForEmailConfirmationController } from "../datalayer/usercrud/callerusercontroller";
import CaptchaModal from "./captcha";
import ReCAPTCHA from "react-google-recaptcha";

const FormItem = Form.Item;

const SignUp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const { Option } = Select;
  const [authDisplay, setAuthDisplay] = useState(false);
  const [authIsOk, setAuthIsOk] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sendbtnDisable, setSendbtnDisable] = useState(true);

  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);
  const [form] = Form.useForm();

  const [captchaValue, setCaptchaValue] = useState(null);
  const [sendBtnDisabled, setSendBtnDisabled] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
    if (value) {
      setSendBtnDisabled(false);
    } else {
      setSendBtnDisabled(true);
    }
  };

  const onFinish = (values) => {
    debugger;
    if (!captchaValue) {
      message.error("Lütfen doğrulamayı tamamlayın!");
      return;
    }
    if (!isFormSubmitted) {
      setFormValues(values);
      setIsFormSubmitted(true);
    }
    console.log("Form Values:", values);
    doitForSign();
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Hata:", errorInfo);
  };

  async function doitForSign() {
    const values = await form.getFieldsValue();
    debugger;

    if (authDisplay && !authIsOk) {
      authControl(values);
    } else if (!authIsOk) {
      sendEmail(values);
    }
  }
  const [htmlContent, setHtmlContent] = useState("");
  const [htmlTitle, setHtmlTitle] = useState("");

  const [checkKvkk, setCheckKvkk] = useState(false);
  const [checkGizlilik, setCheckGizlilik] = useState(false);
  const [checkTuketiciHaklari, setCheckTuketiciHaklari] = useState(false);

  const showModal = (htmlfileinfo) => {
    readHtml(htmlfileinfo);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (e) => {
    console.log(`Checked: ${e.target.checked}`);
    setSendbtnDisable(e.target.checked);
  };
  const onChangeKvkk = (e) => {
    console.log(`Checked: ${e.target.checked}`);
    setCheckKvkk(e.target.checked);
  };
  const onChangeGizlilik = (e) => {
    console.log(`Checked: ${e.target.checked}`);
    setCheckGizlilik(e.target.checked);
  };
  const onChangeTuketiciHaklari = (e) => {
    console.log(`Checked: ${e.target.checked}`);
    setCheckTuketiciHaklari(e.target.checked);
  };

  useEffect(() => {
    if (checkKvkk && checkGizlilik && checkTuketiciHaklari && captchaValue) {
      setSendbtnDisable(false);
    } else {
      setSendbtnDisable(true);
    }
  }, [checkKvkk, checkGizlilik, checkTuketiciHaklari, captchaValue]);

  const authControl = async (values) => {
    console.log("Email Success:", values);
    let userNameLastname = formValues.name + " " + formValues.lastname;

    let req = {
      EmailProcess: 2,
      EmailAddress: formValues.email,
      ConfirmationCode: values.authCode,
      userName: userNameLastname,
    };
    setIsLoading(true);
    let callerSendEmailForEmailConfirmationController =
      await CallerSendEmailForEmailConfirmationController(req);
    setIsLoading(false);

    let convertCharacter =
      callerSendEmailForEmailConfirmationController.replaceAll("'", '"');
    let resultJson = JSON.parse(convertCharacter);

    if (resultJson.success === "True" || resultJson.success === true) {
      dispatch(showAuthLoader());
      dispatch(userSignUp(formValues));
      setAuthIsOk(true);
    } else {
      message.error("Geçersiz veya süresi dolmuş kod. Lütfen tekrar deneyin.");
    }
  };

  const sendEmail = async (values) => {
    let userNameLastname = values.name + " " + values.lastname;
    debugger;
    let req = {
      EmailProcess: 1,
      EmailAddress: values.email,
      ConfirmationCode: "",
      UserName: userNameLastname,
    };
    setIsLoading(true);
    let callerSendEmailForEmailConfirmationController =
      await CallerSendEmailForEmailConfirmationController(req);
    setIsLoading(false);

    console.log(
      "callerSendEmailForEmailConfirmationController",
      callerSendEmailForEmailConfirmationController
    );
    debugger;
    if (callerSendEmailForEmailConfirmationController.ReturnCode) {
      message.error(callerSendEmailForEmailConfirmationController.ReturnText);
    } else {
      setAuthDisplay(true);
    }
  };

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/");
    }
  });

  const prefixSelector = (
    <Form.Item name="prefix" noStyle initialValue="90">
      <Select style={{ width: 70 }}>
        <Option value="90">+90</Option>
      </Select>
    </Form.Item>
  );

  function readHtml(htmlfileinfo) {
    let htmlfile = "";
    switch (htmlfileinfo) {
      case "kvkk":
        setHtmlTitle("KVKK Metni");
        htmlfile = "kvkk.html";
        break;
      case "gizlilikveguvenlik":
        setHtmlTitle("Gizlilik Ve Güvenlik Metni");
        htmlfile = "gizlilikveguvenlik.html";
        break;
      case "tuketicihaklaricayma":
        setHtmlTitle("Tüketici Hakları Ve Cayma İptal Hakkı Metni");
        htmlfile = "tuketicihaklaricaymaiptal.html";
        break;

      default:
        break;
    }

    fetch("https://www.awwex.com/" + htmlfile)
      .then((response) => response.text())
      .then((data) => {
        setHtmlContent(data);
        setIsModalVisible(true);
      })
      .catch((error) => console.error("Error loading HTML file:", error));
  }

  return (
    <>
      <Helmet>
        <title>Kayıt Ol - Awwex </title>
      </Helmet>
      
    <div
      className="gx-app-login-wrap"
      style={{
        opacity: 0.8,
        backgroundImage:
          "url('https://www.awwex.com/assets/images/loginbackgrounds/awwexlogin.png')",
      }}
    >
      <div
        className="gx-app-login-container"
        style={{ boxShadow: "3px 3px 9px black" }}
      >
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signUp" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo-white.png" />
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              form={form}
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              {authDisplay ? (
                <>
                  <div style={{ marginBottom: "10px", fontSize: "14px" }}>
                    E-posta adresinize doğrulama kodu gönderilmiştir. Lütfen
                    e-postanızı kontrol ederek gelen kodu aşağıdaki alana
                    giriniz.
                  </div>
                  <FormItem
                    name="authCode"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen kodunuzu girin",
                      },
                    ]}
                  >
                    <Input placeholder="Doğrulama Kodu" />
                  </FormItem>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      disabled={sendbtnDisable}
                      type="primary"
                      className="gx-mb-0"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Doğrula
                    </Button>
                    <Button
                      type="link"
                      onClick={() => setAuthDisplay(false)}
                      style={{
                        fontSize: "16px",
                        padding: "0",
                      }}
                    >
                      Geri Dön
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <FormItem
                    rules={[
                      { required: true, message: "Lütfen adınızı girin!" },
                    ]}
                    name="name"
                  >
                    <Input placeholder="Ad" />
                  </FormItem>
                  <FormItem
                    rules={[
                      { required: true, message: "Lütfen soyadınızı girin!" },
                    ]}
                    name="lastname"
                  >
                    <Input placeholder="Soyad" />
                  </FormItem>
                  <FormItem
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Geçerli bir E-posta adresi girin!",
                      },
                    ]}
                  >
                    <Input placeholder="E-posta" />
                  </FormItem>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: false,
                        message: "Lütfen telefon numaranızı girin!",
                      },
                    ]}
                  >
                    <Input placeholder="Telefon" addonBefore={prefixSelector} />
                  </Form.Item>
                  <FormItem
                    name="password"
                    rules={[
                      { required: true, message: "Lütfen şifrenizi girin!" },
                    ]}
                  >
                    <Input type="password" placeholder="Şifre" />
                  </FormItem>
                  <Form.Item>
                    <ReCAPTCHA
                      sitekey="6LexsbAqAAAAACacU2yXNU7_B5SSM7pLdavaJKeU"
                      onChange={onCaptchaChange}
                    />
                  </Form.Item>

                  <div style={{ textAlign: "left" }}>
                    <Checkbox onChange={onChangeKvkk}>
                      KVKK Metni'ni okudum ve kabul ediyorum.{" "}
                    </Checkbox>
                    <Button
                      style={{ marginLeft: "8px" }}
                      type="link"
                      onClick={() => showModal("kvkk")}
                    >
                      Detaylar için tıklayın
                    </Button>
                    <br />
                    <Checkbox onChange={onChangeGizlilik}>
                      Gizlilik Ve Güvenlik Metni'ni okudum ve kabul ediyorum.{" "}
                    </Checkbox>
                    <Button
                      style={{ marginLeft: "8px" }}
                      type="link"
                      onClick={() => showModal("gizlilikveguvenlik")}
                    >
                      Detaylar için tıklayın
                    </Button>
                    <br />
                    <Checkbox onChange={onChangeTuketiciHaklari}>
                      Tüketici Hakları - Cayma – İptal İade Koşulları Metni'ni
                      okudum ve kabul ediyorum.{" "}
                    </Checkbox>
                    <Button
                      style={{ marginLeft: "8px" }}
                      type="link"
                      onClick={() => showModal("tuketicihaklaricayma")}
                    >
                      Detaylar için tıklayın
                    </Button>
                    <Modal
                      title={htmlTitle}
                      visible={isModalVisible}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      footer={[
                        <Button key="ok" type="primary" onClick={handleOk}>
                          Tamam
                        </Button>,
                      ]}
                      style={{ maxWidth: "790px", width: "790px" }}
                      width={1000}
                    >
                      <div
                        style={{
                          maxHeight: "500px", // Modal içeriğinin maksimum yüksekliği
                          overflowY: "auto", // Dikey kaydırma etkinleştir
                          padding: "10px", // İçerik kenar boşluğu
                        }}
                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                      ></div>
                    </Modal>
                  </div>
                  <FormItem>
                    <Button
                      disabled={sendbtnDisable}
                      type="primary"
                      className="gx-mb-0"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Kaydol
                    </Button>
                    <span>veya</span> <Link to="/signin">Giriş Yap</Link>
                  </FormItem>
                </>
              )}
            </Form>
          </div>
          {loader && (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && message.error(alertMessage)}
        </div>
      </div>
    </div>
    </>
  );
};

export default SignUp;
