import React, { useEffect, useRef } from "react";
import AwwexLandingFooter from "./components/footer";
import AwwexLandingHeader from "./components/header";
import AwwexLandingColoredHeader from "./components/coloredheader";
import AwwexLandingContent from "./components/indexcontent";
import AwwexLandingAbout from "./components/about";
import AwwexLandingPrivileges from "./components/privileges";
import AwwexLandingSSS from "./components/sss";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";

import "./css/stylelanding.css";

function AwwexLandingIndex(props) {
  const location = useLocation();
  const tContainerRef = useRef(null);

  useEffect(() => {
    tContainerRef.current.scrollIntoView({
      behavior: "smooth", // Kaydırmanın yumuşak bir şekilde yapılmasını sağlarız
      block: "start",     // Div'in üst kısmına kaydırır
    });
  }, [location]);

  return (
    <div>
      <div id={"tContainerRef"} ref={tContainerRef}></div>
      <div id="landingpagefixed" class="landingpagefixed">
        {location.pathname === "/" ? <AwwexLandingHeader/> : <AwwexLandingColoredHeader/>}
        <Switch>
          <Route path="/" exact component={AwwexLandingContent}/>
          <Route path="/about" component={AwwexLandingAbout}/>
          <Route path="/privileges" component={AwwexLandingPrivileges}/>
          <Route path="/sss" component={AwwexLandingSSS}/>

        </Switch>

        <AwwexLandingFooter/>
      </div>
    </div>
      );
      }

      export default function App() {
      return (
      <Router>
      <AwwexLandingIndex />
      </Router>
      );
    }
